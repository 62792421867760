package supergenerous.app.core.component.list

/**
 * Item shown inside [list] components.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public data class ListItem(

    /**
     * ID of the [ListItem] (must be different from all other IDs on the same [list]).
     */
    val id: String,

    /**
     * Primary text shown on the [ListItem].
     */
    val primaryText: String,

    /**
     * Secondary text shown on the [ListItem] under the [primaryText], or `null` if no secondary text should be shown.
     */
    val secondaryText: String? = null,

    /**
     * `true` if the checkbox on the [ListItem] should be checked, or `false` otherwise.
     */
    val isChecked: Boolean = false

)