package supergenerous.app.donee.util.component

import com.supergenerous.common.donee.user.DoneeUser
import kotlinx.css.Display
import kotlinx.css.display
import kotlinx.css.height
import kotlinx.css.px
import kotlinx.css.width
import react.RBuilder
import react.RComponent
import react.State
import supergenerous.app.core.util.RouteProps
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.menu.MenuItem
import supergenerous.app.core.component.menu.profileMenu
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.push
import supergenerous.app.core.util.withRouter
import supergenerous.app.donee.util.Url

/**
 * Renders the navigation menu.
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
@JsExport
private class NavMenu : RComponent<NavMenuProps, State>() {

    override fun RBuilder.render() {
        // Profile menu (desktop version)
        styledDiv {
            css {
                mobileScreen { display = Display.none }
            }

            profileMenu(
                user = props.doneeUser,
                items = ProfileMenuOption.values()
                        .filter { !it.isMobileOnly }
                        .map { MenuItem(label = it.label, value = it.name) },
                onSelect = ::onMenuItemSelect
            )
        }

        // Profile menu (mobile version)
        styledDiv {
            css {
                display = Display.none
                mobileScreen { display = Display.block }

                width = 40.px
                height = 40.px
            }

            profileMenu(
                user = props.doneeUser,
                items = ProfileMenuOption.values()
                        .map { MenuItem(label = it.label, value = it.name) },
                onSelect = ::onMenuItemSelect
            )
        }
    }

    /**
     * Performs the action that matches the [menuItem].
     */
    private fun onMenuItemSelect(menuItem: String) {
        when (ProfileMenuOption.valueOf(menuItem)) {
            ProfileMenuOption.DONEE_INFO -> props.history.push(Url.Path.DONEE_INFO)
            ProfileMenuOption.SIGN_OUT -> props.signOut()
        }
    }

    /*
     * Inner types
     */

    /**
     * Options displayed in the profile menu.
     */
    private enum class ProfileMenuOption(

        /**
         * The text to show in the menu for the menu item.
         */
        val label: String,

        /**
         * `true` if the item should only be visible on mobile screens, or `false` if it should be always visible.
         */
        val isMobileOnly: Boolean = false

    ) {

        DONEE_INFO(label = "My organisation", isMobileOnly = true),
        SIGN_OUT(label = "Log out")

    }

}

/**
 * Properties of the [NavMenu] component.
 */
private external interface NavMenuProps : RouteProps {

    /**
     * The signed-in user.
     */
    var doneeUser: DoneeUser

    /**
     * Function to call when the user clicks on the "sign out" button.
     */
    var signOut: () -> Unit

}

/**
 * Renders a [NavMenu] for the [doneeUser].
 */
public fun RBuilder.navMenu(doneeUser: DoneeUser, signOut: () -> Unit) {
    withRouter(NavMenu::class) {
        attrs.doneeUser = doneeUser
        attrs.signOut = signOut
    }
}