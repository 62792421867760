package com.supergenerous.common.contact

import com.supergenerous.common.data.Dbo
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [Contact] class.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class ContactDbo(

    val crmId: String? = null,

    val name: String? = null,

    val email: String? = null,

    val phoneNumber: String? = null,

    val role: String? = null

) : Dbo<Contact> {

    override fun toDto(): Contact {
        return Contact(crmId = crmId,
                       name = name!!,
                       email = email!!,
                       phoneNumber = phoneNumber,
                       role = role)
    }

}