package com.supergenerous.common.bank

import com.supergenerous.common.data.Dbo
import com.supergenerous.common.file.CloudFileDbo
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [BankAccount] class.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class BankAccountDbo(

    val number: String? = null,

    // Property name doesn't start with "is" because of Firebase issue: https://github.com/firebase/firebase-admin-java/issues/472
    // TODO: Change to isVerified when the issue is fixed on Firebase
    val verified: Boolean? = null,

    val proofDoc: CloudFileDbo? = null

) : Dbo<BankAccount> {

    override fun toDto(): BankAccount {
        return BankAccount(number = number!!,
                           isVerified = verified!!,
                           proofDoc = proofDoc!!.toDto())
    }

}