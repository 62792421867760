package supergenerous.app.donee.dashboard.view

import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.user.DoneeUser
import com.supergenerous.common.network.CoreUrl.Path.SIGN_IN
import kotlinx.css.Display
import kotlinx.css.GridColumn
import kotlinx.css.GridRow
import kotlinx.css.display
import kotlinx.css.gridColumn
import kotlinx.css.gridRow
import kotlinx.css.marginBottom
import kotlinx.css.padding
import kotlinx.css.paddingBottom
import kotlinx.css.px
import react.RBuilder
import react.State
import supergenerous.app.core.util.RouteProps
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.heading1
import supergenerous.app.core.component.layout.grid
import supergenerous.app.core.util.component.toolbar
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.push
import supergenerous.app.core.util.withRouter
import supergenerous.app.donee.dashboard.viewmodel.DashboardViewModel
import supergenerous.app.donee.donee.view.doneeInfoPanel
import supergenerous.app.donee.donee.view.doneeStatsPanel
import supergenerous.app.donee.donee.viewmodel.DoneeInfoViewModel
import supergenerous.app.donee.donee.viewmodel.DoneeStatsViewModel
import supergenerous.app.donee.util.component.navMenu

/**
 * Displays [Donee] info and stats.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
private class DashboardScreen : LifecycleOwnerComponent<DashboardScreenProps, State>() {

    override fun RBuilder.render() {
        styledDiv {
            css {
                marginBottom = 48.px
            }

            toolbar(
                actionsRight = {
                    navMenu(
                        doneeUser = props.doneeUser,
                        signOut = props.dashboardViewModel::signOut
                    )
                }
            )
        }

        grid(isCentered = true) {
            css {
                paddingBottom = 24.px

                mobileScreen { paddingBottom = 32.px }
            }

            doneeInfo()

            doneeStats()
        }
    }

    /**
     * Renders the [doneeInfoPanel].
     */
    private fun RBuilder.doneeInfo() {
        styledDiv {
            css {
                gridRow = GridRow("1 / 4")
                gridColumn = GridColumn("1 / 5")
                padding(top = 56.px, right = 24.px, bottom = 24.px, left = 24.px)

                mobileScreen {
                    display = Display.none
                }
            }

            doneeInfoPanel(
                doneeUser = props.doneeUser,
                viewModel = props.doneeInfoViewModel
            )
        }
    }

    /**
     * Renders the [doneeStatsPanel].
     */
    private fun RBuilder.doneeStats() {
        styledDiv {
            css {
                gridColumn = GridColumn("5 / 13")

                mobileScreen {
                    gridColumn = GridColumn("1 / 3")
                    padding(horizontal = 24.px)
                }
            }

            heading1 {
                css {
                    marginBottom = 24.px
                }

                +"Organisation Stats"
            }

            doneeStatsPanel(
                doneeUser = props.doneeUser,
                viewModel = props.doneeStatsViewModel
            )
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        props.dashboardViewModel.signOutSuccessEvent.observeEvent { props.history.push(SIGN_IN) }
    }

}

/**
 * Properties used by the [DashboardScreen].
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
private external interface DashboardScreenProps : RouteProps {

    /**
     * The [doneeUser] that is signed in.
     */
    var doneeUser: DoneeUser

    var dashboardViewModel: DashboardViewModel

    var doneeInfoViewModel: DoneeInfoViewModel

    var doneeStatsViewModel: DoneeStatsViewModel

}

/**
 * Renders the [DashboardScreen].
 */
public fun RBuilder.dashboardScreen(doneeUser: DoneeUser,
                                    dashboardViewModel: DashboardViewModel,
                                    doneeInfoViewModel: DoneeInfoViewModel,
                                    doneeStatsViewModel: DoneeStatsViewModel) {
    withRouter(DashboardScreen::class) {
        attrs.doneeUser = doneeUser
        attrs.dashboardViewModel = dashboardViewModel
        attrs.doneeInfoViewModel = doneeInfoViewModel
        attrs.doneeStatsViewModel = doneeStatsViewModel
    }
}