package com.supergenerous.common.campaign

import com.supergenerous.common.data.Dto
import com.supergenerous.common.donee.Donee
import kotlinx.serialization.Serializable

/**
 * Code set for a third-party (e.g., a [Donee]) for a specific [campaign].
 *
 * @author Murray Kinsman (murray@supergenerous.com)
 */
@Serializable
public data class CampaignCode(

    /**
     * The campaign code, in the format `[Campaign.prefix]-<UNIQUE-ID>`.
     */
    public val code: String,

    /**
     * Campaign the [code] belongs to.
     */
    public val campaign: Campaign

) : Dto<CampaignCodeDbo> {

    override fun toDbo(): CampaignCodeDbo {
        return CampaignCodeDbo(code = code,
                               campaign = campaign)
    }

}