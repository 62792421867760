package com.supergenerous.common.donee.user

import com.supergenerous.common.data.DtoDataModel
import com.supergenerous.common.donee.DoneeId
import com.supergenerous.common.user.UserInfo
import com.supergenerous.common.util.Timestamp
import kotlinx.serialization.Serializable

/**
 * A user that has access to data of the donee with [doneeId].
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
@Serializable
public data class DoneeUser(

    override val id: String,
    override val creationTimestamp: Timestamp = 0,
    override val lastUpdateTimestamp: Timestamp = 0,

    override val name: String,

    override val email: String,

    override val phoneNumber: String? = null,

    override val address: String? = null,

    override val profileImgUrl: String? = null,

    override val signUpDate: Timestamp,

    /**
     * The ID of the donee that the user has access to.
     */
    val doneeId: DoneeId

) : UserInfo, DtoDataModel<DoneeUserDbo> {

    override fun toDbo(): DoneeUserDbo {
        return DoneeUserDbo(id = id,
                            creationTimestamp = creationTimestamp,
                            lastUpdateTimestamp = lastUpdateTimestamp,
                            name = name,
                            email = email,
                            phoneNumber = phoneNumber,
                            address = address,
                            profileImgUrl = profileImgUrl,
                            signUpDate = signUpDate,
                            doneeId = doneeId.value)
    }

}