package supergenerous.app.donee.donee.model

import com.hipsheep.kore.model.repo.Repository
import com.hipsheep.kore.resource.Resource
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.DoneeId
import com.supergenerous.common.donee.DoneeStats

/**
 * [Repository] used to manage [Donee] data.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class DoneeRepository(

    private val doneeService: DoneeService,
    private val doneeStatsService: DoneeStatsService

) : Repository() {

    /**
     * Returns the [Donee] with [id] from the server.
     */
    public suspend fun getDonee(id: DoneeId): Resource<Donee> {
        return getResource { doneeService.getDonee(id = id) }
    }

    /**
     * Returns the [DoneeStats] with [id] from the server.
     */
    public suspend fun getDoneeStats(id: DoneeId): Resource<DoneeStats> {
        return getResource { doneeStatsService.getDoneeStats(id = id) }
    }

}