package com.supergenerous.common.bank

import com.supergenerous.common.data.Dto
import com.supergenerous.common.file.CloudFile
import kotlinx.serialization.Serializable

/**
 * Bank account info.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class BankAccount(

    /**
     * Bank account number.
     */
    val number: BankAccountNumber,

    /**
     * `true` if the bank account is verified by 2 or more SG team members, or `false` otherwise.
     */
    var isVerified: Boolean,

    /**
     * CloudFile for the document that proves the bank account [number] is correct.
     */
    val proofDoc: CloudFile

) : Dto<BankAccountDbo> {

    override fun toDbo(): BankAccountDbo {
        return BankAccountDbo(number = number,
                              verified = isVerified,
                              proofDoc = proofDoc.toDbo())
    }

}