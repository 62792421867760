package com.supergenerous.common.accounting

import com.supergenerous.common.donee.Donee

/**
 * Accounting system used by an organisation (e.g., a [Donee]).
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class AccountingSystem(

    /**
     * Name to show in UIs, logs, etc.
     */
    public val displayName: String

) {

    MYOB(displayName = "MYOB"),
    ACCREDO(displayName = "Accredo"),
    XERO(displayName = "Xero"),
    NETSUITE(displayName = "NetSuite"),
    ATTACHE(displayName = "Attache"),
    MICROSOFT_DYNAMICS_GP(displayName = "Microsoft Dynamics GP")

}