package com.supergenerous.common.donee

import com.supergenerous.common.data.DtoDataModel
import com.supergenerous.common.util.Timestamp
import kotlinx.serialization.Serializable

/**
 * Stats for a [Donee] in the system.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
@Serializable
public data class DoneeStats(

    override val id: String,

    override val creationTimestamp: Timestamp = 0,

    override val lastUpdateTimestamp: Timestamp = 0,

    /**
     * The number of donors that have donated (i.e. a donation exists for them) to this [Donee].
     */
    public val donorsDonatedNum: Int,

    /**
     * The number of donors that have donated a rebate to this [Donee].
     */
    public val donorsDonatedRebateNum: Int,

    /**
     * The number of donations made to the [Donee] in the SG system.
     */
    public val donationsNum: Int,

    /**
     * The total amount that has been donated from rebates to this [Donee].
     */
    public val rebatesDonatedAmount: Float,

    /**
     * The number of sign-ups per month of donors that have donated to this [Donee].
     */
    public val donorSignups: Set<MonthStats<Int>>,

    /**
     * The disbursements sent to the [Donee] per month.
     */
    public val disbursementsSent: Set<MonthStats<Float>>

) : DtoDataModel<DoneeStatsDbo> {

    /**
     * The average amount donated from rebates to this [Donee] per donor.
     */
    public val rebatesDonatedAmountAvg: Float
        get() = if (donorsDonatedRebateNum > 0) rebatesDonatedAmount / donorsDonatedRebateNum else 0f

    override fun toDbo(): DoneeStatsDbo {
        return DoneeStatsDbo(id = id,
                             creationTimestamp = creationTimestamp,
                             lastUpdateTimestamp = lastUpdateTimestamp,
                             donorsDonatedNum = donorsDonatedNum,
                             donorsDonatedRebateNum = donorsDonatedRebateNum,
                             donationsNum = donationsNum,
                             rebatesDonatedAmount = rebatesDonatedAmount,
                             donorSignups = donorSignups.map { it.toDbo() },
                             disbursementsSent = disbursementsSent.map { it.toDbo() })
    }

}