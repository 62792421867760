package supergenerous.app.donee.donee.model

import com.hipsheep.kore.model.network.Response
import com.supergenerous.common.donee.DoneeId
import com.supergenerous.common.donee.DoneeStats
import com.supergenerous.common.donee.DoneeStatsDbo
import com.supergenerous.common.firebase.DONEE_STATS
import com.supergenerous.common.firebase.FirestoreCollection
import com.supergenerous.common.firebase.FirestoreService

/**
 * Service used to access [DoneeStats] data.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class DoneeStatsService(

    collection: FirestoreCollection<DoneeStatsDbo> = FirestoreCollection.DONEE_STATS

) : FirestoreService<DoneeStats, DoneeStatsDbo>(collection) {

    /**
     * Gets the [DoneeStats] with [id] from the server.
     */
    public suspend fun getDoneeStats(id: DoneeId): Response<DoneeStats> {
        return getDoc(id = id.value)
    }

}