package supergenerous.app.donee.donee.viewmodel

import com.hipsheep.kore.viewmodel.BaseViewModel
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValue
import com.supergenerous.common.donee.DoneeStats
import com.supergenerous.common.donee.user.DoneeUser
import kotlinx.coroutines.launch
import supergenerous.app.donee.donee.model.DoneeRepository
import supergenerous.app.donee.donee.view.doneeStatsPanel

/**
 * [ViewModel] that provides data to the [doneeStatsPanel].
 */
public class DoneeStatsViewModel(

    private val doneeRepo: DoneeRepository

) : BaseViewModel() {

    /**
     * Backing field for [doneeStats].
     */
    private val _doneeStats = MutableLiveData<DoneeStats>()
    /**
     * The [doneeStats] loaded via [loadDoneeStats].
     */
    public val doneeStats: LiveData<DoneeStats> = _doneeStats


    /**
     * Loads the stats of the donee linked to [doneeUser].
     */
    public fun loadDoneeStats(doneeUser: DoneeUser) {
        launch {
            executeAction(updateLoadingStatus = false) { doneeRepo.getDoneeStats(id = doneeUser.doneeId) }
                    ?.let { _doneeStats.updateValue(it) }
        }
    }

}