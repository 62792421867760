package com.supergenerous.common.campaign

import com.supergenerous.common.data.Dbo
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [CampaignCode] class.
 */
@Serializable
public data class CampaignCodeDbo(

    val code: String? = null,

    val campaign: Campaign? = null

) : Dbo<CampaignCode> {

    override fun toDto(): CampaignCode {
        return CampaignCode(code = code!!,
                            campaign = campaign!!)
    }

}