package supergenerous.app.donee.dashboard.viewmodel

import com.hipsheep.kore.viewmodel.BaseViewModel
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.event.Event
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValueAsync
import kotlinx.coroutines.launch
import supergenerous.app.core.auth.model.AuthRepository

/**
 * [ViewModel] that provides data to the dashboard screen.
 */
public class DashboardViewModel(

    private val authRepo: AuthRepository

) : BaseViewModel() {

    /**
     * Backing field for [signOutSuccessEvent].
     */
    private val _signOutSuccessEvent = MutableLiveData<Event<Unit>>()
    /**
     * Observable called when a user signs out successfully.
     */
    public val signOutSuccessEvent: LiveData<Event<Unit>> = _signOutSuccessEvent


    /**
     * Signs out the current user.
     */
    public fun signOut() {
        launch {
            executeAction { authRepo.signOut() }
                    ?.let { _signOutSuccessEvent.updateValueAsync(Event(Unit)) }
        }
    }

}