package supergenerous.app.donee.donee.user.model

import com.hipsheep.kore.model.network.Response
import com.supergenerous.common.donee.user.DoneeUser
import com.supergenerous.common.donee.user.DoneeUserDbo
import com.supergenerous.common.firebase.DONEE_USERS
import com.supergenerous.common.firebase.FirestoreCollection
import com.supergenerous.common.firebase.FirestoreService

/**
 * Service used to access [DoneeUser] data.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class DoneeUserService(

    collection: FirestoreCollection<DoneeUserDbo> = FirestoreCollection.DONEE_USERS

) : FirestoreService<DoneeUser, DoneeUserDbo>(collection) {

    /**
     * Gets the [DoneeUser] with [id] from the server.
     */
    public suspend fun getDoneeUser(id: String): Response<DoneeUser> {
        return getDoc(id = id)
    }

}