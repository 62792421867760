package com.supergenerous.common.campaign

/**
 * Marketing or referral campaign run by SG.
 *
 * IMPORTANT: These values should never change as they'll be stored in the server DB.
 */
public enum class Campaign(

    /**
     * Prefix used in the [Campaign] codes.
     *
     * Each prefix must be unique.
     */
    public val prefix: String

) {

    FIVE_DOLLAR_DONEE_REFERRAL(prefix = "sg5"),

    XMAS_2022_DONEE_REFERRAL(prefix = "X22")

}
