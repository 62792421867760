package supergenerous.app.donee.donee.view

import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.user.DoneeUser
import kotlinx.css.GridColumn
import kotlinx.css.gridColumn
import kotlinx.css.marginBottom
import kotlinx.css.padding
import kotlinx.css.paddingBottom
import kotlinx.css.px
import react.RBuilder
import react.State
import supergenerous.app.core.util.RouteProps
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.layout.grid
import supergenerous.app.core.util.component.toolbar
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.core.util.push
import supergenerous.app.core.util.withRouter
import supergenerous.app.donee.donee.viewmodel.DoneeInfoViewModel
import supergenerous.app.donee.util.Url

/**
 * Screen that displays the info of the [Donee] linked to the [DoneeInfoScreenProps.doneeUser].
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private class DoneeInfoScreen : LifecycleOwnerComponent<DoneeInfoScreenProps, State>() {

    override fun RBuilder.render() {
        styledDiv {
            css {
                marginBottom = 48.px
            }

            toolbar(
                onBackBtnClick = { props.history.push(Url.Path.DASHBOARD) }
            )
        }

        grid(isCentered = true) {
            css {
                paddingBottom = 24.px

                mobileScreen { paddingBottom = 32.px }
            }

            styledDiv {
                css {
                    gridColumn = GridColumn("4 / 10")
                    padding(top = 0.px, right = 24.px, bottom = 24.px, left = 24.px)

                    mobileScreen {
                        gridColumn = GridColumn("1 / 3")
                    }
                }

                doneeInfoPanel(
                    doneeUser = props.doneeUser,
                    viewModel = props.viewModel
                )
            }
        }
    }

}

/**
 * Properties of the [DoneeInfoScreen].
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface DoneeInfoScreenProps : RouteProps {

    /**
     * [DoneeUser] linked to the donee that should be displayed.
     */
    var doneeUser: DoneeUser

    var viewModel: DoneeInfoViewModel

}

/**
 * Renders the [DoneeInfoScreen].
 */
public fun RBuilder.doneeInfoScreen(doneeUser: DoneeUser, viewModel: DoneeInfoViewModel) {
    withRouter(DoneeInfoScreen::class) {
        attrs.doneeUser = doneeUser
        attrs.viewModel = viewModel
    }
}