package supergenerous.app.donee.donee.user.model

import com.hipsheep.kore.error.ErrorType.AppError
import com.hipsheep.kore.model.repo.Repository
import com.hipsheep.kore.resource.Resource
import com.hipsheep.kore.resource.Resource.*
import com.supergenerous.common.donee.user.DoneeUser
import com.supergenerous.common.user.User
import supergenerous.app.core.auth.model.AuthError.NOT_SIGNED_IN

/**
 * [Repository] used to manage [DoneeUser] data.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class DoneeUserRepository(

    private val doneeUserService: DoneeUserService

) : Repository() {

    /**
     * [DoneeUser] linked to the signed-in user, or `null` if it was not created yet or no user is signed in.
     */
    // TODO: Check if there is a better way to cache this
    private var doneeUser: DoneeUser? = null


    /**
     * Returns the [DoneeUser] belonging to [user] from the server.
     */
    public suspend fun getDoneeUser(user: User): Resource<DoneeUser> {
        return getResource {
            val resp = doneeUserService.getDoneeUser(id = user.id)

            // Intercept the value so it can be cached inside the repository to use it later
            if (resp.isSuccessful) {
                doneeUser = resp.body
            }

            resp
        }
    }

    /**
     * Returns the [DoneeUser] belonging to the signed-in user.
     */
    public suspend fun getDoneeUser(): Resource<DoneeUser> {
        return if (doneeUser != null) Success(doneeUser!!) else Error(AppError(NOT_SIGNED_IN))
    }

}