package com.supergenerous.common.donee

import com.supergenerous.common.data.Dbo
import com.supergenerous.common.data.DboDataModel
import com.supergenerous.common.util.Timestamp
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [DoneeStats] class.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
@Serializable
public data class DoneeStatsDbo(

    override var id: String? = null,

    override var creationTimestamp: Timestamp? = null,

    override var lastUpdateTimestamp: Timestamp? = null,

    public val donorsDonatedNum: Int? = null,

    public val donorsDonatedRebateNum: Int? = null,

    public val donationsNum: Int? = null,

    public val rebatesDonatedAmount: Float? = null,

    public val donorSignups: List<MonthStatsDbo<Int>>? = null,

    public val disbursementsSent: List<MonthStatsDbo<Float>>? = null

) : DboDataModel<DoneeStats> {

    override fun toDto(): DoneeStats {
        return DoneeStats(id = id!!,
                          creationTimestamp = creationTimestamp!!,
                          lastUpdateTimestamp = lastUpdateTimestamp!!,
                          donorsDonatedNum = donorsDonatedNum!!,
                          donorsDonatedRebateNum = donorsDonatedRebateNum!!,
                          donationsNum = donationsNum!!,
                          rebatesDonatedAmount = rebatesDonatedAmount!!,
                          donorSignups = donorSignups!!.map { it.toDto() }.toSet(),
                          disbursementsSent = disbursementsSent!!.map { it.toDto() }.toSet())
    }

}