package com.supergenerous.common.donee

import com.supergenerous.common.data.Dto
import com.supergenerous.common.serialization.LocalDateSerializerString
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

/**
 * Status that indicates if donation tax rebates can be claimed for a [Donee] or not.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class TaxClaimStatus(

    /**
     * `true` if the [Donee] is approved by the government to claim donation tax rebates for donations made to
     * them, or `false` if they are not.
     */
    val isApproved: Boolean,

    /**
     * Date at which the tax claim status stops/stopped being valid for the [Donee].
     */
    @Serializable(LocalDateSerializerString::class)
    val ceaseDate: LocalDate?

) : Dto<TaxClaimStatusDbo> {

    override fun toDbo(): TaxClaimStatusDbo {
        return TaxClaimStatusDbo(approved = isApproved,
                                 ceaseDate = ceaseDate?.toString())
    }

}