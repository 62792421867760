package supergenerous.app.donee.donee.view

import com.supergenerous.common.contact.Contact
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.Donee.Type.SCHOOL
import com.supergenerous.common.donee.user.DoneeUser
import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.GridColumn
import kotlinx.css.GridTemplateColumns
import kotlinx.css.GridTemplateRows
import kotlinx.css.Overflow
import kotlinx.css.OverflowWrap
import kotlinx.css.Position
import kotlinx.css.TextAlign
import kotlinx.css.WordBreak
import kotlinx.css.alignItems
import kotlinx.css.display
import kotlinx.css.fr
import kotlinx.css.gap
import kotlinx.css.gridColumn
import kotlinx.css.gridTemplateColumns
import kotlinx.css.gridTemplateRows
import kotlinx.css.margin
import kotlinx.css.marginBottom
import kotlinx.css.overflow
import kotlinx.css.overflowWrap
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.position
import kotlinx.css.px
import kotlinx.css.textAlign
import kotlinx.css.width
import kotlinx.css.wordBreak
import react.Props
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.dividerHorizontal
import supergenerous.app.core.component.subheading1
import supergenerous.app.core.component.subheading2
import supergenerous.app.core.component.tooltip.helpIcon
import supergenerous.app.core.util.mobileScreen
import supergenerous.app.donee.donee.viewmodel.DoneeInfoViewModel

/**
 * Component that displays the info of the [Donee] linked to the [DoneeInfoPanelProps.doneeUser].
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
@JsExport
private class DoneeInfoPanel : LifecycleOwnerComponent<DoneeInfoPanelProps, DoneeInfoPanelState>() {

    private val gridColumnFullWidth = GridColumn("1 / 3")


    override fun RBuilder.render() {
        styledDiv {
            css {
                width = 100.pct
                display = Display.grid
                gridTemplateColumns = GridTemplateColumns(2.fr, 3.fr)
                gridTemplateRows = GridTemplateRows.auto
                gap = 20.px
            }

            state.donee?.let {
                doneeInfo(donee = it)
            }
        }
    }

    /**
     * Renders the info of the [donee].
     */
    private fun RBuilder.doneeInfo(donee: Donee) {
        styledDiv {
            css {
                gridColumn = gridColumnFullWidth
                display = Display.flex
                alignItems = Align.center
                gap = 8.px
            }

            subheading1 { +donee.name }
        }

        styledDiv {
            css {
                mobileScreen { display = Display.none }
                gridColumn = gridColumnFullWidth
            }

            sectionDivider()
        }

        donee.legalName?.let {
            infoField(
                label = "Official name",
                value = it
            )
        }

        if (!donee.otherNames.isNullOrEmpty()) {
            infoField(
                label = "Other name(s)",
                values = donee.otherNames!!
            )
        }

        donee.govId?.let {
            infoField(
                label = if (donee.type == SCHOOL) "MoE number" else "CC number",
                value = it
            )
        }

        infoField(
            label = "Disbursement email(s)",
            values = donee.disbursementEmails.ifEmpty { listOf(donee.receiptRequestEmail) },
            helpInfo = "These emails will be notified when we send money to your organisation"
        )

        donee.bankAccount?.number?.let {
            infoField(
                label = "Bank account number",
                value = it
            )
        }

        donee.address?.let {
            infoField(
                label = "Office address",
                value = it
            )
        }

        infoField(
            label = "Email address",
            value = donee.email
        )

        infoField(
            label = "Website",
            value = donee.websiteUrl
        )

        donee.phoneNumber?.let {
            infoField(
                label = "Phone number",
                value = it
            )
        }

        if (donee.crms.isNotEmpty()) {
            infoField(
                label = "CRMs",
                values = donee.crms.map { it.displayName }
            )
        }

        donee.accountingSystem?.let {
            infoField(
                label = "Accounting system",
                value = it.displayName
            )
        }

        donee.mainContact?.let {
            contactInfo(
                contactType = "Main contact",
                contact = it
            )
        }

        donee.receiptsHandler?.let {
            contactInfo(
                contactType = "Receipts handler",
                contact = it
            )
        }
    }

    /**
     * Renders a [contact]'s info under the label [contactType].
     */
    private fun RBuilder.contactInfo(contactType: String, contact: Contact) {
        sectionDivider()

        subheading2 { +contactType }

        infoField(
            label = "Name",
            value = contact.name
        )

        contact.role?.let {
            infoField(
                label = "Role",
                value = it
            )
        }

        infoField(
            label = "Email address",
            value = contact.email
        )

        contact.phoneNumber?.let {
            infoField(
                label = "Phone number",
                value = it
            )
        }
    }

    /**
     * Renders an info field with a single item.
     */
    private fun RBuilder.infoField(label: String,
                                   value: String,
                                   helpInfo: String? = null) {
        infoField(
            label = label,
            values = listOf(value),
            helpInfo = helpInfo
        )
    }

    /**
     * Renders an info field with a list of items.
     */
    private fun RBuilder.infoField(label: String,
                                   values: Collection<String>,
                                   helpInfo: String? = null) {
        styledDiv {
            css {
                gridColumn = GridColumn("1 / 2")
                display = Display.flex
            }

            subheading2 { +label }

            helpInfo?.let {
                styledDiv {
                    css {
                        padding(all = 8.px)
                    }

                    helpIcon(it)
                }
            }
        }

        styledDiv {
            css {
                gridColumn = GridColumn("2 / 3")

                /*
                 * `overflow: hidden` + `position: relative` stops the elements being scrollable. Without the `relative`
                 * the element is scrollable. This is especially noticeable on Macs because scrolling is "elastic".
                 */
                overflow = Overflow.hidden
                position = Position.relative

                mobileScreen {
                    textAlign = TextAlign.right
                }
            }

            values.forEach { value ->
                body1 {
                    css {
                        position = Position.relative
                        wordBreak = WordBreak.normal
                        overflowWrap = OverflowWrap.anywhere

                        // Add a slight gap between each item
                        marginBottom = 4.px
                        lastChild {
                            marginBottom = 0.px
                        }


                    }

                    +value
                }
            }
        }
    }

    /**
     * Renders a [dividerHorizontal] spanning the full width of the parent view.
     */
    private fun RBuilder.sectionDivider() {
        styledDiv {
            css {
                gridColumn = GridColumn("1 / 3")

                // override the margin on the divider because the panel uses Gap for spacing
                specific { children { margin(vertical = 0.px) } }
            }

            dividerHorizontal()
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        props.viewModel.donee.observe { setState { donee = it } }

        props.viewModel.loadDonee(doneeUser = props.doneeUser)
    }

}

/**
 * Properties of the [DoneeInfoPanel].
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface DoneeInfoPanelProps : Props {

    /**
     * [DoneeUser] linked to the donee that should be displayed.
     */
    var doneeUser: DoneeUser

    var viewModel: DoneeInfoViewModel

}

/**
 * State of the [DoneeInfoPanel].
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface DoneeInfoPanelState : State {

    /**
     * Donee to display the info of.
     */
    var donee: Donee?

}

/**
 * Renders the [DoneeInfoPanel].
 */
public fun RBuilder.doneeInfoPanel(doneeUser: DoneeUser, viewModel: DoneeInfoViewModel) {
    child(DoneeInfoPanel::class) {
        attrs.doneeUser = doneeUser
        attrs.viewModel = viewModel
    }
}