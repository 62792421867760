package supergenerous.app.donee

import browser.document
import react.Props
import react.createElement
import react.dom.client.createRoot
import supergenerous.app.core.initApp
import supergenerous.app.core.util.EnvConfig
import supergenerous.app.core.util.appRouter

/**
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public fun main() {
    // Initialise the application
    initApp(EnvConfig, enablePerformanceMonitoring = true)

    createRoot(document.getElementById("root")!!).render(createElement<Props> {
        appRouter { app() }
    })
}