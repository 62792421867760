package com.supergenerous.common.donee

import com.supergenerous.common.accounting.AccountingSystem
import com.supergenerous.common.bank.BankAccountDbo
import com.supergenerous.common.campaign.Campaign
import com.supergenerous.common.campaign.CampaignCodeDbo
import com.supergenerous.common.contact.ContactDbo
import com.supergenerous.common.crm.Crm
import com.supergenerous.common.data.Dbo
import com.supergenerous.common.data.DboDataModel
import com.supergenerous.common.donation.platform.DonationPlatform
import com.supergenerous.common.file.CloudFileDbo
import com.supergenerous.common.util.Timestamp
import com.supergenerous.common.util.toLocalDate
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [Donee] class.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class DoneeDbo(

    override var id: String? = null,

    override var creationTimestamp: Timestamp? = null,

    override var lastUpdateTimestamp: Timestamp? = null,

    var crmId: String? = null,

    val type: Donee.Type? = null,

    var govId: String? = null,

    val name: String? = null,

    val legalName: String? = null,

    val otherNames: List<String>? = null,

    val websiteUrl: String? = "",

    val email: String? = "",

    val disbursementEmails: List<String>? = null,

    val mainContact: ContactDbo? = null,

    val receiptsHandler: ContactDbo? = null,

    val phoneNumber: String? = null,

    val address: String? = null,

    val logo: CloudFileDbo? = null,

    val bankAccount: BankAccountDbo? = null,

    val taxClaimStatus: TaxClaimStatusDbo? = null,

    val donationPlatforms: List<DonationPlatform>? = null,

    val donationPlatformLinks: List<DonationPlatformLinkDbo>? = null,

    val crms: List<Crm>? = null,

    val accountingSystem: AccountingSystem? = null,

    val canProvideReceipts: Boolean? = null,

    val bankAccRequestSent: Boolean? = null,

    // Property name doesn't start with "is" because of Firebase issue: https://github.com/firebase/firebase-admin-java/issues/472
    // TODO: Change to isInFafCommunity when the issue is fixed on Firebase
    val inFafCommunity: Boolean? = null,

    val partnerValue: Donee.PartnerValue? = null,

    val optedOutOfCommunity: Boolean? = null,

    var communityJoinDate: Timestamp? = null,

    val activeIndividualDonorsNum: Long? = null,

    val individualDonationsAmountLastYear: Float? = null,

    val regularGivingDonorsNum: Long? = null,

    val optedOutOfDonations: Boolean? = null,

    val campaignCodes: List<CampaignCodeDbo>? = null,

    val competitorPartners: List<Competitor>? = null,

    val repeatDonorReceiptsAutomated: Boolean? = null,

    val crmConnected: Boolean? = null,

    /*
     * DB props only
     */

    /**
     * List of IDs of the donation platforms associated with this donee (the same list as [donationPlatforms]).
     *
     * This value is only used for search purposes on the DB.
     */
    val donationPlatformIds: List<String>? = null,

    /**
     * List of donation platform links associated with this donee (the same list as [donationPlatformLinks]).
     *
     * This value is only used for search purposes on the DB.
     */
    val donationPlatformLinksIds: List<String>? = null,

    /**
     * [Campaign]s the donee participated in (i.e., it has [campaignCodes] for them).
     *
     * This value is only used for search purposes on the DB.
     */
    val campaigns: List<Campaign>? = null

) : DboDataModel<Donee> {

    override fun toDto(): Donee {
        return Donee(id = id!!,
                     creationTimestamp = creationTimestamp!!,
                     lastUpdateTimestamp = lastUpdateTimestamp!!,
                     crmId = crmId,
                     type = type!!,
                     govId = govId,
                     name = name!!,
                     legalName = legalName,
                     otherNames = otherNames?.toSet(),
                     websiteUrl = websiteUrl ?: "",
                     email = email ?: "",
                     disbursementEmails = disbursementEmails?.toSet() ?: emptySet(),
                     mainContact = mainContact?.toDto(),
                     receiptsHandler = receiptsHandler?.toDto(),
                     phoneNumber = phoneNumber,
                     address = address,
                     logo = logo?.toDto(),
                     bankAccount = bankAccount?.toDto(),
                     taxClaimStatus = taxClaimStatus!!.toDto(),
                     donationPlatforms = donationPlatforms?.toSet() ?: emptySet(),
                     donationPlatformLinks = donationPlatformLinks?.map { it.toDto() }?.toSet() ?: emptySet(),
                     crms = crms?.toSet() ?: emptySet(),
                     accountingSystem = accountingSystem,
                     canProvideReceipts = canProvideReceipts ?: true,
                     bankAccRequestSent = bankAccRequestSent ?: false,
                     isInFafCommunity = inFafCommunity ?: false,
                     partnerValue = partnerValue,
                     optedOutOfCommunity = optedOutOfCommunity ?: false,
                     communityJoinDate = communityJoinDate?.toLocalDate(),
                     activeIndividualDonorsNum = activeIndividualDonorsNum,
                     individualDonationsAmountLastYear = individualDonationsAmountLastYear,
                     regularGivingDonorsNum = regularGivingDonorsNum,
                     optedOutOfDonations = optedOutOfDonations ?: false,
                     campaignCodes = campaignCodes?.map { it.toDto() }?.toSet() ?: emptySet(),
                     competitorPartners = competitorPartners?.toSet() ?: emptySet(),
                     isRepeatDonorReceiptsAutomated = repeatDonorReceiptsAutomated ?: false,
                     isCrmConnected = crmConnected ?: false)
    }

    /*
     * Inner types
     */

    /**
     * [Dbo] corresponding to the [Donee.DonationPlatformLink] class.
     */
    @Serializable
    public data class DonationPlatformLinkDbo(

        val donationPlatform: DonationPlatform? = null,

        val doneeExtId: String? = null

    ) : Dbo<Donee.DonationPlatformLink> {

        override fun toDto(): Donee.DonationPlatformLink {
            return Donee.DonationPlatformLink(donationPlatform = donationPlatform!!, doneeExtId = doneeExtId!!)
        }

    }
}