package supergenerous.app.core.component.list

import materialui.list.MuiList
import materialui.list.MuiListItem
import materialui.list.MuiListItemIcon
import materialui.list.MuiListItemSecondaryAction
import materialui.list.MuiListItemText
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.buildElement
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.body2
import supergenerous.app.core.component.checkbox

/**
 * List component used throughout the app.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@JsExport
private class List : RComponent<ListProps, State>() {

    override fun RBuilder.render() {
        @Suppress("DEPRECATION")
        MuiList {
            for (item in props.items) {
                MuiListItem {
                    attrs {
                        button = props.onItemClick != null
                        divider = true
                        selected = item.isChecked
                        props.onItemClick?.let { onClick = { it.invoke(item) } }
                    }

                    MuiListItemText {
                        attrs {
                            // Remove typography to fix a React crash because we use our own styling
                            disableTypography = true

                            primary = buildElement { body1 { +item.primaryText } }
                            item.secondaryText?.let { secondary = buildElement { body2 { +it } } }
                        }
                    }

                    props.onItemCheckChange?.let { onItemCheckChange ->
                        MuiListItemSecondaryAction {
                            MuiListItemIcon {
                                checkbox(
                                    isChecked = item.isChecked,
                                    onChange = {
                                        // Change isChecked value before calling the callback
                                        onItemCheckChange(item.copy(isChecked = !item.isChecked))
                                    }
                                )
                            }
                        }
                    }
                }
            }
        }
    }

}

/**
 * Properties used by the [List] component.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
private external interface ListProps : Props {

    /**
     * Items to show on the [List].
     */
    var items: kotlin.collections.List<ListItem>

    /**
     * Function called when a [ListItem] is clicked.
     *
     * If this value is not set, then the list items won't be clickable.
     */
    var onItemClick: ((ListItem) -> Unit)?

    /**
     * Function called when the checkbox on a [ListItem] changes state.
     *
     * If this value is not set, then the checkboxes won't be shown.
     */
    var onItemCheckChange: ((ListItem) -> Unit)?

}

/**
 * Renders a [List] component.
 */
public fun RBuilder.list(items: kotlin.collections.List<ListItem>,
                         onItemClick: ((ListItem) -> Unit)? = null,
                         onItemCheckChange: ((ListItem) -> Unit)? = null) {
    child(List::class) {
        attrs.items = items
        attrs.onItemClick = onItemClick
        attrs.onItemCheckChange = onItemCheckChange
    }
}