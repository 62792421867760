package com.supergenerous.common.donee

import com.supergenerous.common.data.Dto
import kotlinx.datetime.Month
import kotlinx.serialization.Serializable

/**
 * Represents a data point [value] that occurred in [year] and [month].
 */
@Serializable
// "T : Any" forces T to be a non-nullable type, as there are issues when deserialising back to the DTO from DBO if the
// value is null
public data class MonthStats<T : Any>(

    public val year: Int,

    public val month: Month,

    public val value: T

) : Dto<MonthStatsDbo<T>> {

    override fun toDbo(): MonthStatsDbo<T> {
        return MonthStatsDbo(year = year,
                             month = month,
                             value = value)
    }

}
