package com.supergenerous.common.crm

import com.supergenerous.common.donee.Donee

/**
 * CRM system used by an organisation (e.g., a [Donee]).
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class Crm(

    /**
     * Name to show in UIs, logs, etc.
     */
    public val displayName: String

) {

    /**
     * A CRM that was created exclusively for the organisation.
     */
    CUSTOM(displayName = "Custom / Bespoke"),

    KEELA(displayName = "Keela"),
    RAISERS_EDGE(displayName = "Blackbaud Raiser's Edge"),
    BLACKBAUD_ETAPESTRY(displayName = "Blackbaud eTapestry"),
    RAISELY(displayName = "Raisely"),
    MICROSOFT_DYNAMICS(displayName = "Microsoft Dynamics"),
    SALESFORCE(displayName = "Salesforce"),
    INFOODLE(displayName = "Infoodle"),
    MAILCHIMP(displayName = "Mailchimp"),
    VEGA(displayName = "Vega"),
    KAMAR(displayName = "Kamar"),
    KINDFUL(displayName = "Kindful"),
    KINDO(displayName = "Kindo"),
    SHAREPOINT(displayName = "SharePoint"),
    THANKQ(displayName = "ThankQ"),
    VOLABY(displayName = "Volaby"),
    CLARETY(displayName = "Clarety"),
    DONORBOX(displayName = "Donorbox"),
    PCSCHOOL(displayName = "PCSchool"),
    FUNDRAISER_ONE(displayName = "FundraiserOne"),
    FUNDRAISER_SUITE(displayName = "Fundraiser Suite"),
    ETAP(displayName = "eTAP"),
    SYNERGETIC(displayName = "Synergetic"),
    HERO(displayName = "Hero / Linc-Ed")

}