package supergenerous.app.donee

import com.supergenerous.common.donee.user.DoneeUser
import com.supergenerous.common.network.CoreUrl.Path.*
import react.RBuilder
import react.router.dom.Switch
import react.setState
import supergenerous.app.core.BaseApp
import supergenerous.app.core.BaseAppState
import supergenerous.app.core.analytics.AnalyticsRepository
import supergenerous.app.core.analytics.FirebaseAnalyticsService
import supergenerous.app.core.auth.model.AuthRepository
import supergenerous.app.core.auth.view.authScreen
import supergenerous.app.core.auth.viewmodel.AuthViewModel
import supergenerous.app.core.pageNotFoundScreen
import supergenerous.app.core.res.image.CoreImage
import supergenerous.app.core.util.RouteProps
import supergenerous.app.core.util.redirect
import supergenerous.app.core.util.route
import supergenerous.app.core.util.withRouter
import supergenerous.app.donee.dashboard.view.dashboardScreen
import supergenerous.app.donee.dashboard.viewmodel.DashboardViewModel
import supergenerous.app.donee.donee.model.DoneeRepository
import supergenerous.app.donee.donee.model.DoneeService
import supergenerous.app.donee.donee.model.DoneeStatsService
import supergenerous.app.donee.donee.user.model.DoneeUserRepository
import supergenerous.app.donee.donee.user.model.DoneeUserService
import supergenerous.app.donee.donee.view.doneeInfoScreen
import supergenerous.app.donee.donee.viewmodel.DoneeInfoViewModel
import supergenerous.app.donee.donee.viewmodel.DoneeStatsViewModel
import supergenerous.app.donee.util.Url.Path.*


/**
 * Main component that contains the whole app.
 */
private class App : BaseApp<RouteProps, AppState, AppViewModel>() {

    override val splashScreenLogo = CoreImage.SG_LOGO_GREEN

    override val rootPath = ROOT
    override val authPaths = setOf(SIGN_IN)

    override var afterAuthPath: String? = null

    private val analyticsRepo = AnalyticsRepository(analyticsServices = listOf(FirebaseAnalyticsService()))
    private val authRepo = AuthRepository()
    private val doneeRepo = DoneeRepository(doneeService = DoneeService(),
                                            doneeStatsService = DoneeStatsService())
    private val doneeUserRepo = DoneeUserRepository(doneeUserService = DoneeUserService())

    override val appViewModel = AppViewModel(authRepo = authRepo, doneeUserRepo = doneeUserRepo)
    private val authViewModel = AuthViewModel(authRepo = authRepo, analyticsRepo = analyticsRepo)


    override fun AppState.initAppState() {
        isDoneeUserLoading = false
    }

    override fun RBuilder.onAppLoaded() {
        // Start tracking screens views for analytics
        // TODO: Re-enable
        //  screenTracker(analyticsRepository)

        Switch {
            route(ROOT, exact = true) { redirect(to = DASHBOARD) }

            route(SIGN_IN) {
                authScreen(
                    viewModel = authViewModel,
                    isSignIn = true,
                    enableSignUp = false,
                    enableAuthProviders = false,
                    isUserDataLoading = state.isDoneeUserLoading,
                    title = "Superpartner Login",
                    leftPanelTitle = "We make claiming donation rebates easy.",
                    leftPanelSubtitle = "And regifting them to your organisation? Even easier.",
                    resetPasswordText = "Reset password"
                )
            }

            route(DASHBOARD, exact = true) {
                requireAuth {
                    state.doneeUser?.let {
                        dashboardScreen(
                            doneeUser = it,
                            dashboardViewModel = DashboardViewModel(authRepo = authRepo),
                            doneeInfoViewModel = DoneeInfoViewModel(doneeRepo = doneeRepo),
                            doneeStatsViewModel = DoneeStatsViewModel(doneeRepo = doneeRepo)
                        )
                    }
                }
            }

            route(DONEE_INFO, exact = true) {
                requireAuth {
                    state.doneeUser?.let {
                        doneeInfoScreen(
                            doneeUser = it,
                            viewModel = DoneeInfoViewModel(doneeRepo = doneeRepo)
                        )
                    }
                }
            }

            // Catch-all route to display 404 screen
            route(ROOT, exact = false) { pageNotFoundScreen() }
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        // Load donee user data once the user authenticates successfully
        authViewModel.authSuccessEvent.observeEvent {
            appViewModel.loadDoneeUser(user = it.user)
        }

        // Show loading icon on auth button when the donee user is being loaded (this is done to avoid the button in
        // AuthScreen from appearing not loading for a second after the user authenticates)
        appViewModel.isActionInProgress.observe { setState { isDoneeUserLoading = it } }

        // When the donee user is loaded then move to the next screen
        appViewModel.doneeUser.observe {
            setState { doneeUser = it }

            // If the user is just trying to navigate to a specific page then send them there, otherwise send them to
            // the dashboard
            val urlPath = afterAuthPath ?: DASHBOARD.value

            props.history.push(path = urlPath)
        }
    }

}

/**
 * State of the [App] component.
 */
private external interface AppState : BaseAppState {

    /**
     * `true` if the donee user's data is being loaded, or `false` if the loading ended (either successfully or an error
     * occurred).
     */
    var isDoneeUserLoading: Boolean

    /**
     * Donee user currently signed in.
     */
    var doneeUser: DoneeUser?

}

/**
 * Renders the [App] component.
 */
public fun RBuilder.app() {
    withRouter(App::class)
}