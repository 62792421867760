package supergenerous.app.donee.donee.viewmodel

import com.hipsheep.kore.viewmodel.BaseViewModel
import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValue
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.user.DoneeUser
import kotlinx.coroutines.launch
import supergenerous.app.donee.donee.model.DoneeRepository
import supergenerous.app.donee.donee.view.doneeInfoPanel

/**
 * [ViewModel] that provides data to the [doneeInfoPanel].
 */
public class DoneeInfoViewModel(

    private val doneeRepo: DoneeRepository

) : BaseViewModel() {

    /**
     * Backing field for [donee].
     */
    private val _donee = MutableLiveData<Donee>()
    /**
     * The [Donee] loaded via [loadDonee].
     */
    public val donee: LiveData<Donee> = _donee


    /**
     * Loads the donee linked to [doneeUser].
     */
    public fun loadDonee(doneeUser: DoneeUser) {
        launch {
            executeAction(updateLoadingStatus = false) { doneeRepo.getDonee(id = doneeUser.doneeId) }
                    ?.let { _donee.updateValue(it) }
        }
    }

}