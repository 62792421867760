package supergenerous.app.donee.donee.view

import com.supergenerous.common.donee.DoneeStats
import com.supergenerous.common.donee.MonthStats
import com.supergenerous.common.donee.user.DoneeUser
import com.supergenerous.common.util.displayName
import com.supergenerous.common.util.toString
import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.GridAutoRows
import kotlinx.css.GridTemplateColumns
import kotlinx.css.JustifyContent
import kotlinx.css.Overflow
import kotlinx.css.alignItems
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.gap
import kotlinx.css.gridAutoRows
import kotlinx.css.gridTemplateColumns
import kotlinx.css.justifyContent
import kotlinx.css.maxHeight
import kotlinx.css.overflowY
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import kotlinx.datetime.LocalDate
import react.Props
import react.RBuilder
import react.State
import react.setState
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.LifecycleOwnerComponent
import supergenerous.app.core.component.contentSection
import supergenerous.app.core.component.list.ListItem
import supergenerous.app.core.component.list.list
import supergenerous.app.core.component.subheading2
import supergenerous.app.core.component.tile.tile
import supergenerous.app.core.component.tooltip.helpIcon
import supergenerous.app.donee.donee.viewmodel.DoneeStatsViewModel

/**
 * Component that displays the [DoneeStats] of the donee linked to the [DoneeStatsPanelProps.doneeUser].
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
@JsExport
private class DoneeStatsPanel : LifecycleOwnerComponent<DoneeStatsPanelProps, DoneeStatsPanelState>() {

    override fun RBuilder.render() {
        styledDiv {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                gap = 24.px
            }

            state.doneeStats?.let {
                doneeStats(doneeStats = it)
            }
        }
    }

    /**
     * Renders the [doneeStats].
     */
    private fun RBuilder.doneeStats(doneeStats: DoneeStats) {
        styledDiv {
            css {
                width = 100.pct
                display = Display.grid
                gridTemplateColumns = GridTemplateColumns.repeat("auto-fit, ${160.px.value}")
                gridAutoRows = GridAutoRows(72.px)
                gap = 16.px
            }

            tile(
                label = "Number of donors",
                value = doneeStats.donorsDonatedNum.toString(),
                helpInfo = "Number of Supergenerous donors who have donated to your organisation"
            )

            tile(
                label = "Number of regifting donors",
                value = doneeStats.donorsDonatedRebateNum.toString(),
                helpInfo = "Number of donors who regifted rebates to your organisation via Supergenerous"
            )

            tile(
                label = "Number of donations",
                value = doneeStats.donationsNum.toString(),
                helpInfo = "Number of donations processed by Supergenerous from your organisation"
            )

            tile(
                label = "Total amount regifted",
                value = "$${doneeStats.rebatesDonatedAmount.toString(precision = 2)}",
                helpInfo = "Total amount donated to your organisation from rebates claimed by Supergenerous"
            )

            tile(
                label = "Average amount regifted",
                value = "$${doneeStats.rebatesDonatedAmountAvg.toString(precision = 2)}",
                helpInfo = "Average rebate amount regifted to your organisation per \"regifting\" donor"
            )
        }

        styledDiv {
            css {
                display = Display.flex
                justifyContent = JustifyContent.stretch
                gap = 24.px
            }

            monthStatsList(
                label = "Donor sign-ups by month",
                helpInfo = "Number of donors who have donated to your organisation that signed up to Supergenerous per month",
                values = doneeStats.donorSignups,
            )

            monthStatsList(
                label = "Regifted amount by month",
                helpInfo = "Amount regifted to your organisation from rebates claimed by Supergenerous per month",
                values = doneeStats.disbursementsSent,
                mapValue = { "$${it.toString(precision = 2)}" }
            )
        }
    }

    /**
     * Renders a list with [label] and [values], with each value transformed by the [mapValue] function.
     */
    private fun <T : Any> RBuilder.monthStatsList(label: String,
                                                  values: Set<MonthStats<T>>,
                                                  helpInfo: String? = null,
                                                  mapValue: (T) -> String = { it.toString() }) {
        val listItems = values
                .sortedByDescending { monthStats ->
                    LocalDate(year = monthStats.year,
                              month = monthStats.month,
                              dayOfMonth = 1)
                }
                .map { monthStat ->
                    ListItem(id = "${monthStat.year}-${monthStat.month.ordinal}",
                             primaryText = mapValue(monthStat.value),
                             secondaryText = "${monthStat.month.displayName} ${monthStat.year}")
                }

        contentSection {
            styledDiv {
                css {
                    width = 100.pct
                }

                styledDiv {
                    css {
                        display = Display.flex
                        justifyContent = JustifyContent.spaceBetween
                        alignItems = Align.flexStart
                        gap = 8.px
                    }

                    subheading2 { +label }

                    helpInfo?.let { helpIcon(helpInfo = it) }
                }

                styledDiv {
                    css {
                        width = 100.pct
                        maxHeight = 640.px
                        overflowY = Overflow.auto
                    }

                    list(listItems)
                }
            }
        }
    }

    override fun componentDidMount() {
        super.componentDidMount()

        props.viewModel.doneeStats.observe { setState { doneeStats = it } }

        props.viewModel.loadDoneeStats(doneeUser = props.doneeUser)
    }

}

/**
 * Properties of the [DoneeStatsPanel].
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface DoneeStatsPanelProps : Props {

    /**
     * [DoneeUser] linked to the donee of the stats that should be displayed.
     */
    var doneeUser: DoneeUser

    var viewModel: DoneeStatsViewModel

}

/**
 * State of the [DoneeStatsPanel].
 *
 * @author Cameron Probert (cameron@supergenerous.co.nz)
 */
private external interface DoneeStatsPanelState : State {

    /**
     * Donee stats to display.
     */
    var doneeStats: DoneeStats?

}

/**
 * Renders the [DoneeStatsPanel].
 */
public fun RBuilder.doneeStatsPanel(doneeUser: DoneeUser, viewModel: DoneeStatsViewModel) {
    child(DoneeStatsPanel::class) {
        attrs.doneeUser = doneeUser
        attrs.viewModel = viewModel
    }
}