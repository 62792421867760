package com.supergenerous.common.contact

import com.supergenerous.common.data.Dto
import kotlinx.serialization.Serializable

/**
 * Person that forms part of an organisation (e.g., in a [Donee] org.).
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class Contact(

    /**
     * The CRM ID of the contact.
     */
    val crmId: String? = null,

    /**
     * Full name of the contact.
     */
    val name: String,

    /**
     * Email of the contact.
     */
    val email: String,

    /**
     * Phone number of the contact.
     */
    val phoneNumber: String? = null,

    /**
     * Role of the contact in the organisation they belong to.
     */
    val role: String?

) : Dto<ContactDbo> {

    override fun toDbo(): ContactDbo {
        return ContactDbo(crmId = crmId,
                          name = name,
                          email = email,
                          phoneNumber = phoneNumber,
                          role = role)
    }

}