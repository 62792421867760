package supergenerous.app.donee.util

/**
 * List of URLs used in the app.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public enum class Url(

    override val value: String

) : com.supergenerous.common.network.Url {

    SG_WEBSITE("https://www.supergenerous.co.nz");

    /*
     * Inner types
     */

    /**
     * List of URL paths used in the app.
     */
    public enum class Path(override val value: String) : com.supergenerous.common.network.Url.Path {

        DASHBOARD("/dashboard"),

        DONEE_INFO("/donee-info")

    }

}