package supergenerous.app.core.component.tile

import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.GridTemplateRows
import kotlinx.css.JustifyContent
import kotlinx.css.alignItems
import kotlinx.css.display
import kotlinx.css.fr
import kotlinx.css.gap
import kotlinx.css.gridTemplateRows
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import react.RBuilder
import styled.css
import styled.styledDiv
import supergenerous.app.core.component.body1
import supergenerous.app.core.component.body2
import supergenerous.app.core.component.contentSection
import supergenerous.app.core.component.tooltip.helpIcon

/**
 * Renders a data tile with [label] and [value]. If [helpInfo] is defined, then adds a help pop-up to the tile.
 */
public fun RBuilder.tile(label: String,
                         value: String,
                         helpInfo: String? = null) {
    contentSection {
        styledDiv {
            css {
                display = Display.grid
                gridTemplateRows = GridTemplateRows(2.fr, 3.fr)
                width = 100.pct
                height = 100.pct
            }

            body1 { +value }

            styledDiv {
                css {
                    display = Display.flex
                    justifyContent = JustifyContent.spaceBetween
                    alignItems = Align.flexStart
                    gap = 8.px
                }

                body2 { +label }

                helpInfo?.let { helpIcon(helpInfo = it) }
            }
        }
    }
}