package com.supergenerous.common.donee

import com.supergenerous.common.data.Dbo
import kotlinx.datetime.Month
import kotlinx.serialization.Serializable

/**
 * The [Dbo] for [MonthStats].
 */
@Serializable
public data class MonthStatsDbo<T : Any>(

    public val year: Int? = null,

    public val month: Month? = null,

    public val value: T? = null

) : Dbo<MonthStats<T>> {

    override fun toDto(): MonthStats<T> {
        return MonthStats(year = year!!,
                          month = month!!,
                          value = value!!)
    }

}
