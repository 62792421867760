package supergenerous.app.donee

import com.hipsheep.kore.viewmodel.ViewModel
import com.hipsheep.kore.viewmodel.lifecycle.LiveData
import com.hipsheep.kore.viewmodel.lifecycle.MutableLiveData
import com.hipsheep.kore.viewmodel.lifecycle.updateValue
import com.supergenerous.common.donee.user.DoneeUser
import com.supergenerous.common.user.User
import kotlinx.coroutines.launch
import supergenerous.app.core.BaseAppViewModel
import supergenerous.app.core.auth.model.AuthRepository
import supergenerous.app.donee.donee.user.model.DoneeUserRepository

/**
 * [ViewModel] that provides data to the [App] component.
 */
public class AppViewModel(

    authRepo: AuthRepository,
    private val doneeUserRepo: DoneeUserRepository

) : BaseAppViewModel(authRepo) {

    /**
     * Backing property for [doneeUser].
     */
    private val _doneeUser = MutableLiveData<DoneeUser>()
    /**
     * Observable that is called when the data for the signed-in [DoneeUser] is loaded.
     */
    public val doneeUser: LiveData<DoneeUser> = _doneeUser


    override suspend fun loadUserData(user: User) {
        loadDoneeUser(user = user)
    }

    /**
     * Loads the [DoneeUser] associated with the signed in [user] from the server.
     */
    public fun loadDoneeUser(user: User) {
        launch {
            executeAction { doneeUserRepo.getDoneeUser(user) }?.let { doneeUser ->
                _doneeUser.updateValue(doneeUser)
                _isAppDataLoaded.updateValue(true)
            }
        }
    }

}