package supergenerous.app.donee.donee.model

import com.hipsheep.kore.model.network.Response
import com.supergenerous.common.donee.Donee
import com.supergenerous.common.donee.DoneeDbo
import com.supergenerous.common.donee.DoneeId
import com.supergenerous.common.firebase.DONEES
import com.supergenerous.common.firebase.FirestoreCollection
import com.supergenerous.common.firebase.FirestoreService

/**
 * Service used to access [Donee] data.
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
public class DoneeService(

    collection: FirestoreCollection<DoneeDbo> = FirestoreCollection.DONEES

) : FirestoreService<Donee, DoneeDbo>(collection) {

    /**
     * Gets the [Donee] with [id] from the server.
     */
    public suspend fun getDonee(id: DoneeId): Response<Donee> {
        return getDoc(id = id.value)
    }

}