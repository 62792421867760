package com.supergenerous.common.donee

/**
 * List of SG competitors (i.e., companies that provide the same services).
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
public enum class Competitor {

    TAXGIFT

}