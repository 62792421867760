package com.supergenerous.common.donee.user

import com.supergenerous.common.data.DboDataModel
import com.supergenerous.common.donee.DoneeId
import com.supergenerous.common.util.Timestamp
import kotlinx.serialization.Serializable

/**
 * DBO of [DoneeUser].
 *
 * @author Cameron Probert (cameron@supergenerous.com)
 */
@Serializable
public data class DoneeUserDbo(

    override var id: String? = null,

    override var creationTimestamp: Timestamp? = null,

    override var lastUpdateTimestamp: Timestamp? = null,

    val name: String? = null,

    val email: String? = null,

    val phoneNumber: String? = null,

    val address: String? = null,

    val profileImgUrl: String? = null,

    val signUpDate: Timestamp? = null,

    val doneeId: String? = null

) : DboDataModel<DoneeUser> {

    override fun toDto(): DoneeUser {
        return DoneeUser(id = id!!,
                         creationTimestamp = creationTimestamp!!,
                         lastUpdateTimestamp = lastUpdateTimestamp!!,
                         name = name!!,
                         email = email!!,
                         phoneNumber = phoneNumber,
                         address = address,
                         profileImgUrl = profileImgUrl,
                         signUpDate = signUpDate!!,
                         doneeId = DoneeId(doneeId!!))
    }
}
