package com.supergenerous.common.donee

import com.supergenerous.common.data.Dbo
import kotlinx.datetime.toLocalDate
import kotlinx.serialization.Serializable

/**
 * [Dbo] corresponding to the [TaxClaimStatus] class.
 *
 * @author Franco Sabadini (franco@supergenerous.com)
 */
@Serializable
public data class TaxClaimStatusDbo(

    // Property name doesn't start with "is" because of Firebase issue: https://github.com/firebase/firebase-admin-java/issues/472
    // TODO: Change to isApproved when the issue is fixed on Firebase
    val approved: Boolean? = null,

    val ceaseDate: String? = null

) : Dbo<TaxClaimStatus> {

    override fun toDto(): TaxClaimStatus {
        return TaxClaimStatus(isApproved = approved!!,
                              ceaseDate = ceaseDate?.toLocalDate())
    }

}